/* btn */
%buttonEffect {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, .2);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }

  &.focus,
  &:focus {
    &:not(:active)::before {
      animation: ripple 1s ease-out;
    }
  }
}

.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
}

.btn {
  cursor: pointer;
  font-family: OpenSans6;

  &.focus,
  &:focus {
    box-shadow: none;
    color: $white;
  }

  &--green {
    background: $green;
    color: $white !important;
    @extend %buttonEffect;

    &:hover {
      // background: darken($green, 3);
      color: $white;
    }
  }

  &--reset {
    background: #e2bc00;
    color: $txt-blue !important;
    @extend %buttonEffect;

    &:hover {
      background: #be9f02;
      color: $white;
    }
  }

  &--blue {
    background: $base-blue;
    color: $white !important;
    @extend %buttonEffect;

    // padding: .3rem;
    &:hover,
    &:link {
      // background: darken($blue, 3);
      color: $white !important;
    }
  }

  &--fix {
    // padding: .58rem 0.75rem;
    font-size: 14px;
    height: 46px;
  }

  &--red {
    background: $white;
    border: 1px solid $red !important;
    border-radius: 4px;
    color: $red !important;
    @extend %buttonEffect;
    // padding: .3rem;
    width: 100%;

    &:hover,
    &:link {
      // background: darken($red, 3);
      color: $white !important;
    }
  }

  &--grey {
    background: $background-grey;
    // border: 1px solid $gray !important;
    border-radius: 4px;
    color: $black !important;
    @extend %buttonEffect;
    // padding: .3rem;
    min-width: 100px;

    &:hover,
    &:link {
      background: $input-border !important;
    }
  }

  &--greens {
    background: $white;
    border: 1px solid $green !important;
    border-radius: 4px;
    color: $green !important;
    @extend %buttonEffect;
    // padding: .3rem;
    width: 100%;

    &:hover,
    &:link {
      // background: darken($green, 3);
      color: $white !important;
    }
  }

  &--white {
    background: $white;
    color: $blue;
    border-color: $blue;

    &:hover,
    &:link {
      // background: darken($white, 8);
      color: $blue;
    }
  }

  &--trans {
    background: transparent;
    color: $green;
    border-color: transparent;

    &:focus {
      color: $green;
    }
  }

  &--darkblue {
    background: $dark-blue;
    border-color: $dark-blue;
    color: $white;

    &:hover,
    &:link {
      background: transparent;
      border: 1px solid $dark-blue;
      color: $dark-blue;
    }
  }

  &--fullWidth {
    width: 100%;
  }
}

.btn--mua {
  padding: 0.3rem 1rem;
  text-transform: none;
  height: 30px;
}


.btn--green {
  &.hidden {
    display: none;
  }
}

.btn-fix .btn {
  padding: 0.5rem 25px 0.5rem 0.5rem;
}

.btn-icon,
.btn-icon:hover {
  background-color: transparent;
  border: none;
  padding: 5px;

  img {
    width: 25px;
  }
}

.btn-marg__top {
  margin-top: 15px;
}

.btn.disabled,
.btn:disabled {
  img {
    opacity: 0.4;
    filter: alpha(opacity=50);
    /* For IE8 and earlier */
  }
}

#dk-gd {
  .btn--green {
    border: 1px solid $green;

    &:hover {
      background: transparent !important;
      color: $green !important;
      border: 1px solid $green;
    }
  }
}

.btn-outline-success,
.btn-outline-success:focus {
  color: $green;
  background-image: none;
  background-color: transparent;
  border-color: $green;

  &:hover {
    color: #fff;
    background-color: $green;
    border-color: $green;
  }
}


.thanhtoan {
  white-space: nowrap;
  position: relative;

  .down-thanhtoan {
    position: absolute;
    bottom: 8px;
    left: 45%;
    width: 14px;
    height: 12px;
  }
}

.btn--td_edit {
  background: transparent;
  padding: 0;

  img {

    @media (max-width: 480px) {
      position: relative;
      left: -3px;
    }

    @media (max-width: 360px) {
      left: -5px;
      top: -1px;
    }
  }
}

.btn-transfer {
  // border: 2px solid $orange;
  border-radius: 4px;
  background: $btn-orange;
  color: #000000 !important;
  font-family: OpenSans6;
  padding: .5rem;
  margin-left: 15px;
  height: 42px;
  min-width: 191px;

  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    color: $white !important;
    // background: transparent !important;
  }

  @media screen and (max-width: 480px) {
    min-width: auto;
  }

}

.btn-outline-blue {
  color: $base-blue !important;
  border: 1px solid $base-blue;
  background-color: $white;

}

.btn-exportIssuer {
  padding: 0;
  background-color: transparent; // float: left;
  margin-left: 10px;
  margin-right: 5px;

  img {
    width: 24px;
    height: 24px;
  }

  a {
    color: $blue !important;
  }
}

.btn-export {
  padding: 10px 12px;
  // margin-bottom: .5rem;

  max-height: 45px;
  min-width: 169px;
  max-width: 212px;
  // width: 100%;

  color: $base-blue !important;
  background-color: $white;

  align-items: center;
  display: flex;
  justify-content: center;

  border-radius: 4px;
  border-width: 2px;
  border: 1px solid $base-blue;


  &:hover,
  &:focus {
    text-decoration: none;
  }

  +.dropdown-menu {
    min-width: 110px;
    right: 0;
    left: initial;
    margin-top: 0;
    animation: fadeInDown 0.3s forwards;

    width: 100%;
    margin-top: 5px;

    li {
      button {
        width: 100%;

        &:hover {
          background: #F5F9FF;
          color: var(--primary-color);
        }
      }
    }
  }
}

.btn-success {
  background-color: $success-button;
  color: $white !important;

  &:hover {
    background-color: $success-button;
    opacity: 0.8;
  }
}

.btn-primary {
  background-color: $primary-button;
  color: $white !important;

  &:hover {
    background-color: $primary-button;
    opacity: 0.8;
  }
}

.btn-active {
  background-color: #e0edff;
  border: 1px solid #5b9cfd;
}

.btn+.dropdown-toggle {
  margin-left: -4px;
}

.btn-copy,
.btn-copy2 {
  padding: 0.25rem 1rem;
  width: 84px;
  border: 1px solid $green;
  background-color: transparent;
  color: $green;

  &.btn-success {
    background-color: $green;
    color: white;
  }
}
