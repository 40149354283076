.loginPage {
  // min-height: -webkit-calc(100vh - 89px);
  // min-height: calc(100vh - 89px);

  min-height: 416px;

  // min-height: -webkit-calc(100vh - 140px);
  // min-height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  overflow: hidden;

  background: $backgroundUrl;
  background-position: 0px -289px;
  position: relative;
  background-size: cover;
  // background-position: bottom;
  background-position: center;
  // background-size: contain;
  // -webkit-background-size: contain;
  // -moz-background-size: contain;
  // -o-background-size: contain;

  margin-top: -45px;

  @media screen and (min-width: 818px) {
    margin-top: -84px;
  }

  @media screen and (max-width: 992px) {
    margin-top: -84px;
  }

  @media screen and (max-width: 576px) {
    margin-top: -125px;
  }

  &__inner {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 15px;
  }

  &__register {
    max-width: 740px;
    margin: 0 auto;
  }

  &__logo {
    padding: 30px;
    text-align: center;
    min-height: 85px;

    img {
      width: 200px;
    }
  }
}

.loginContainer {
  .card {
    border: none;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }

  .card-header {
    background: transparent;
    border-radius: 0;
    padding: 0;
  }

  .card-block {
    padding: 2rem;

    button[type="submit"] {
      font-size: 1rem;
      padding: 10px;
    }

    .btn--login {
      height: 48px;
      background-color: $primary-button;
      color: $white;
      text-transform: uppercase;
    }

    a {
      font-size: 1rem;
    }

    .row {
      padding: 5px;
    }

    .form-group {
      margin-bottom: 0.8rem;
      padding-left: 7.5px;
      padding-right: 7.5px;
    }

    .form-control {
      border: 1px solid #a3a7aa;
      max-height: $inp_height;

      // font-size: 13px;
      @include placeholder {
        color: $dusty-gray !important;
        font-size: calc(14/16) * 1rem;
        font-style: italic;
      }

      @media (max-width: 767px) {
        height: 48px !important;
      }
    }

    label {
      margin-bottom: 0.2rem;
      color: $black;
      font-size: 14px;

      a {
        font-size: 14px;
      }
    }
  }

  .guide-register {
    font-size: 1rem;
    color: $black;
    padding: 0px 10px 20px;
    font-weight: 500;
  }

  .short-link {
    color: $blue !important;
  }

  .nav-tabs {
    li {
      width: 50%;
      text-align: center;

      .nav-link {
        border-radius: 0;
        border: 0;
        background: #ededed;
        color: $black;
        font-weight: 600;
        padding: 12px;

        &.active {
          background: $blue;
          color: $white;
        }
      }
    }
  }

  @include placeholder {
    font-size: 0.7rem;
  }

  .card-title {
    color: $white;
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 400;
    padding: 20px 0;
  }
}

:-moz-ui-invalid:not(output) {
  box-shadow: none;
}




.loginContainer {
  margin-top: -337px;

  @media only screen and (max-width: 576px) {
    margin-top: -285px;
  }
}

.card-header {
  background-color: transparent;
}
