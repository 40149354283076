@import "../global/variables";

@media (min-width: 450px) {
  .otp-notice-not-register {
    .modal-content {
      min-width: 430px;
    }
  }
}

@media (min-width: 1200px) {
  .info-payment-popup {
    .modal-dialog {
      min-width: 891px;
    }
  }
}

.info-payment-popup {
  .modal-content {
    max-height: 814px;
  }
}

.modal-body {
  // padding: 15px 20px;

  .popup-title {
    font: 16px OpenSans7;
    line-height: 24px;
    text-align: center;
    color: #30363D;
    margin-top: 20px;
  }

  .popup-message {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #30363D;
    // margin-top: 20px;
  }
}

.modal-footer {
  padding: 15px 30px;
  border-top: none;
  overflow: hidden;

  @media (max-width: 480px) {
    padding: 15px;
  }

  .text-center {
    margin: 0 auto;
  }
}

.modal--style1 {
  max-width: 500px;
  min-width: 350px;
}

.modal--style2 {
  min-width: 600px;

  .modal-body {
    padding: 0 15px;
    border-radius: 5px;
    margin-bottom: 5px;
  }
}

#buy-fund {
  .modal--style1 {
    min-width: 451px;
  }
}

.view-product-modal {
  .modal-content {
    width: 500px;
  }
}


@media (min-width: 450px) {
  .sell-product-modal {
    .modal-dialog {
      width: 502px !important;
    }
  }
}

.e-sign-modal {
  .modal-dialog {
    @media screen and (max-width:991px) {
      width: 100%;
      height: 100%;
      margin: 0 !important;
      padding: 0;
      max-width: 100%;
    }
  }
}

.buy-product-modal .detail-product-modal {
  .modal-dialog {
    max-width: 1000px !important;
    max-height: 700px !important;

    .modal-content {
      width: 1000px !important;
      height: 700px !important;
      overflow: auto;
    }
  }
}

.error-popup-modal,
.success-popup-modal {
  .modal-dialog {
    max-width: 400px;
    min-width: 380px;

    // width: 100%;
    @media (max-width: 480px) {
      min-width: 280px;
    }
  }
}

.error-popup-modal {
  z-index: 1100;
}

.notice-account-bank-connect-gold {
  color: $dark-blue;
  font-family: myfont_bold !important;
}

.detail-product-modal {
  .modal-dialog {
    width: 95%;
    max-width: 1200px;
  }
}

.detail-fullscreen-fund-modal {
  .modal-dialog {
    width: 100%;
    max-width: 5000px;
    margin: 0px;
  }

  .modal-content {
    max-height: 5000px;
    height: 100vh;
  }
}

.recurring-investment-modal {
  .modal-dialog {
    width: 95%;
    max-width: 502px;
  }
}

.payment-info-modal {
  .modal-dialog {
    width: 95%;
    max-width: 600px;
  }
}

.sell-gold {
  .modal-dialog {
    width: 95%;
    max-width: 600px;
  }
}

.register-withdraw-modal {
  .modal-dialog {
    max-width: 769px;
    width: 95%;
  }
}

.detail-ipo-fund {
  .modal-dialog {
    max-width: 757px;
  }
}

.detail-gold {
  .modal-dialog {
    max-width: 912px;
    min-width: 450px;

    @media (max-width: 480px) {
      width: 95%;
      min-width: 250px;

    }
  }
}

.notice-account-bank-connect-gold-modal {
  .modal-dialog {
    max-width: 409px;
  }
}

.detail-term-reward-modal {
  .modal-dialog {
    max-width: 757px;
  }
}

.not-enough-buy-reward-modal {
  .modal-dialog {
    min-width: 345px;
  }
}

.buy-process-timeline-modal,
.sell-process-timeline-modal,
.transfer-process-timeline-modal {
  .modal-dialog {
    min-width: 330px;
    width: 95%;
  }
}

.large-modal {
  .modal-dialog {
    width: 95%;
    max-width: 900px;
  }
}

.medium-modal {
  .modal-dialog {
    width: 95%;
    max-width: 502px;
  }
}

.notice-change-id-card-modal {
  .modal-dialog {
    max-width: 600px;
    width: 100%;

    @media (max-width: 576px) {
      max-width: 360px;
    }

    @media (max-width: 375px) {
      max-width: 350px;
    }
  }
}

.notice-need-confirm-update-name {
  .modal-dialog {
    max-width: 500px;

    .modal-content {
      border-radius: 10px;
    }
  }
}

.qr-code-reward-modal {
  .modal-dialog {
    max-width: 507px;
    width: 100%;

    @media (max-width: 375px) {
      max-width: 350px;
    }

    @media (max-width: 576px) {
      max-width: 360px;
    }

  }
}

.sign-success-modal {
  .modal-dialog {
    max-width: 400px;
  }
}

.passport-ocr-modal {
  .modal-dialog {
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh;
    top: 0px;
    position: fixed;
    left: 0px;
    margin-top: 0px;

    .modal-content {
      max-height: 100vh;
    }
  }
}

.notice-cart-modal {
  .modal-dialog {
    @media screen and (max-width: 365px) {
      width: 95%;
    }
  }
}

.recurring-investment-modal {
  .dropdown-content {
    position: relative !important;
  }
}

.introduce-investor-modal {
  .modal-dialog {
    width: 502px;

    @media screen and (max-width: 480px) {
      max-width: 350px;
      width: auto;
    }
  }
}

.info-payment-popup,
.confirm-type-investor-modal {
  .modal-dialog {
    max-width: 700px;
  }
}

.view-image-modal {
  .modal-dialog {
    max-width: 900px;
  }
}

loading-modal {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.318);
  transition: all ease 1s;
  z-index: 1060;
}

.detail-product-gold-modal {
  .modal-dialog {
    min-width: 1000px;

    @media (max-width: 480px) {
      width: 95%;
      min-width: 95%;
    }
  }
}

.buy-gold-modal {
  .modal-dialog {
    min-width: 500px;

    @media only screen and (max-width: 480px) {
      width: 95%;
      min-width: 95%;
    }
  }
}

.cashback-order-modal {
  .modal-dialog {
    min-width: 500px;

    @media only screen and (max-width: 480px) {
      width: 95%;
      min-width: 95%;
    }
  }
}

.cashback-success-modal {
  .modal-dialog {
    max-width: 502px;
    width: 100%;
  }
}


.external-egold-modal {
  .modal-dialog {
    max-width: 100%;
    position: relative;
    margin: 0 auto;
  }
}

.eivn-update-fatca-modal {
  .modal-dialog {
    max-width: 586px;
  }
}

.eivn-update-information-modal {
  .modal-dialog {
    max-width: 704px;
  }
}

.notice-fatca-eivn-modal {
  .modal-dialog {
    max-width: 559px;
  }
}
