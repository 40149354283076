@import "../global/variables";
@import "../global/mixins";

%table-style {
  background-color: $white;
  border: 1px solid $table-border;
  border-radius: 5px;
  animation: fadeIn .5s 0.3s ease-out;
  animation-fill-mode: both;
  margin-bottom: 30px;

  .table__title {
    color: $black;
    font-size: calc(25/16)*1rem;
    border-radius: 4px;
    padding: 15px;

    span {
      font-family: OpenSans6;
      font-size: 24px;

      @media (max-width: 767px) {
        font-family: tablefont_bold !important;
        font-size: 18px;
      }

      img {
        margin-right: 10px;
      }
    }
    p.notice {
      font-size: 1rem;
      font-style: italic;
      font-family: myfont;
      color: #000;
      line-height: 0.5;
    }
  }
  
  .table__title--group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: var(--Gray-3, #828282);
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;

      margin-bottom: 0;
    }
  }

  .table thead {
    background-color: $table-bg;

    th {
      vertical-align: middle;
      text-align: left;
      height: 44px;
      color: $black;
      font-weight: normal;
      font: 0.8rem OpenSans6;
      white-space: pre;

      border: none;

      @media screen and (max-width: 480px) {
        font-size: 12px;
      }
    }
  }

  .table td {
    vertical-align: middle;
    text-align: left;
    font-size: 16px;
    border-top: none;
    background-color: $white;

    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
  }

  .btn--edit {
    padding: 0 0 0 5px;
    background-color: transparent;
    position: absolute;
    right: -4px;
    // top: 50%;
    bottom: -8px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    img {
      width: 14px;
    }
  }

  .edit-nav {
    padding: .5rem;
    background-color: transparent;

    img {
      width: 14px;
    }
  }

  .btn--edits {
    padding: 0 0 0 5px;
    background-color: transparent;
    position: absolute;
    left: 6px;
    // top: 50%;
    top: 10px;
    transform: translateY(-50%);

    img {
      width: 14px;
    }
  }

 

  tbody {
    .btn {
      border: 0;
      font: 14px OpenSans6;
    }
  }
}


%btn-icon {
  position: absolute;
  right: 4px;
}

.table {
  margin-bottom: 0;
}

.table-style {
  @extend %table-style;
}

.table-style-2 {
  @extend %table-style;
}

.table thead {
  th {
    font-weight: normal;
  }
}

.btn-delete {
  background: $background-grey;
  color: $tab-text !important;

  img {
    width: 20px;
    height: 20px;
  }
}

/* Paging Table Total*/

.table-total {
  paging {
    nav {
      // border-top: 2px solid $dark_blue;
    }
  }
}



.table-style-sum {

  // &.table-style-sum {
  //   border-bottom: 2px solid #034581;
  // }
  .sum,
  .up,
  .down {
    font-size: 1.1rem !important;
  }
}

.notice-text {
  font-size: 0.8rem;
  font-style: italic;
}

/* Fix bg table inside table */

td table {
  tr {
    background: transparent !important;
  }
}

/* table icon */

.table-icon {
  white-space: nowrap;

  >span {
    width: calc(100% - 25px);
    display: inline-block;
  }

  .btn {
    margin: 0;
  }
}

.ccq-moi {
  .table-striped tfoot tr:first-child {
    background-color: rgba(0, 0, 0, .05);
  }
}

#ccq-gd,
.ccq-moi{
  table {
    .active-link.active {
      color: $blue;
    }
  }
}

.sum {

  button,
  button:focus {
    color: $blue !important;
  }
}

.table__title {
  @media only screen and (max-width: 480px) {
    padding-bottom: 10px;

    .no-padding-right {
      padding-right: 15px !important;
    }
  }

  .row {
    margin: 0 10px;
  }
}


tfoot {
  tr {
    td {
      border-radius: 5px;
    }
  }
}

/* Table on tablet landscape */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .table-style {
    .btn-delete {
      right: 0;
    }
  }
}

@media only screen and (min-width: 568px) and (max-width: 1199px) {
  .table-style {
    .table {

      th,
      td {
        font-size: 13px !important;
        white-space: inherit;
        padding-left: 2px;
        padding-right: 2px;
      }

      td.total-sum {
        width: 100px;
      }

      .btn {
        font-size: 11px;
        font-family: tablefont;
      }
    }

    .hidden-col {
      display: none;
    }
  }

  .table-style-sum {

    .sum,
    .up,
    .down {
      font-size: 1rem !important;
    }
  }

  .btn--mua {
    height: inherit;
  }
}
.table-responsive {
  overflow-y: visible !important;
  table {
    tbody>tr {
      border-bottom: 1px solid #EDF2FA;
    }

    tbody {
      tr:last-child {
        // border-bottom: none;

        td:last-child,
        td:first-child {
          border-radius: 5px;
        }
      }
    }
  }
}
table.header {
  tbody {
    tr {
      background-color: #fafafa !important;
    }
  }
}