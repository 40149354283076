%navbarIcon {
  .navbar-toggler {
    border: 0;
    height: 34px;
    padding: 4px 0px;

    @media screen and (max-width: 576px) {
      height: 41px;
    }

    &:focus,
    &:active {
      outline: 0;
    }

    span {
      background-color: $white;
      display: block;
      height: 2px;
      width: 20px;
      // margin-top: 4px;
      margin-bottom: 4px;
      @include transform(rotate(0deg));
      opacity: 1;
    }

    span:nth-child(1),
    span:nth-child(3) {
      @include transition(transform .35s ease-in-out);
    }
  }

  .navbar-toggler-right {
    right: inherit;
    top: 0;
  }
}

.header {
  // border-bottom: 1px solid rgba(237, 242, 250, 0.3);
  z-index: 2;
  position: relative;

  &__bg {
    background: $base-blue;
  }


  .img-header {
    width: 100%;
    height: 84px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      color: $white;
      text-align: center;
      margin-bottom: 0px;
      margin-top: 10px;
    }

    @media only screen and (max-width: 992px) {
      // height: 44px;
      height: auto;
      padding-top: 15px;
    }

    img {
      width: 100px;
      height: 39px;

      @media only screen and (max-width: 576px) {
        margin-top: 20px;
      }
    }
  }
}

.show-menu {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  touch-action: none;
  -ms-touch-action: none;

}

@mixin navbarActive() {
  &.active {
    background-color: #0368FF !important;
    height: 100%;
    z-index: 99;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    animation: inherit !important;

    .navbar-toggler {

      span:nth-child(1),
      span:nth-child(3) {
        @include transition(transform .35s ease-in-out);
        background-color: #ffffff !important;
      }

      span:nth-child(1) {
        margin-top: 5px;
        @include transform(rotate(135deg));
        opacity: 0.9;
      }

      span:nth-child(2) {
        height: 13px;
        visibility: hidden;
        background-color: transparent;
      }

      span:nth-child(3) {
        margin-top: -23px;
        @include transform(rotate(-135deg));
        opacity: 0.9;
      }
    }
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  margin-top: 0px;

  img {
    max-width: 100%;
  }
}

#navbar {
  width: auto;
  margin-right: auto;
}

#navbar .nav-item {
  margin-left: 5px;
}

#navbar a {
  color: $white;
  font-size: 1.1rem;

  &:hover,
  &.active {
    color: $hover-menu
  }

  @media only screen and (max-width: 480px) {
    color: $white !important;
  }
}

.btn-login {
  text-transform: unset;
  font-size: calc(16.2/$root)*1rem;
}

.header-top {
  // margin-top: -100px;
  // min-height: 591px;
  background: $backgroundUrl;

  // background-attachment: fixed;
  position: relative;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  @media (max-width) {
    min-width: 489px;
  }

  @media (max-width: 1024px) {
    background-attachment: scroll;
  }

  @media (max-width: 767px) {
    padding-bottom: 85px !important;
    min-height: 380px;
    height: 100% !important;
  }

  @media screen and (max-width: 576px) {
    margin-top: -140px !important;
  }

  .banner-inner {
    max-width: 900px;
    margin: 0 auto;
    padding-top: 135px;

    // @media (max-width: 1024px) {
    //   padding-top: 83px;
    // }

    // @media only screen and (max-width: 767px) and (min-width: 569px) {
    //   padding-top: 59px;
    // }

    @media (max-width: 420px) {
      padding-top: 100px;
    }
  }
}


.navbar-investor {
  background-color: $dark-blue;
  min-height: 56px;
  @extend %navbarIcon;

  .navbar {
    padding: 0;
  }

  .navbar-nav .nav-link {
    color: $white !important;
    padding-right: 0rem;
    padding-left: 2rem;
    font-size: 15px;
  }

  .navbar-nav .active>.nav-link,
  .navbar-nav .nav-link:hover {
    color: #00F0FF !important;
  }

  .navbar-right {
    position: relative;
    justify-content: flex-end;
    margin-right: 0;

    .nav-link {
      padding-left: 0;
    }

    .dropdown-item {
      padding: 3px 1rem;
    }

    .sign_out {
      background: $blue !important;
      text-align: center;
      color: $white;
      width: calc(100% - 2rem);
      margin: 0 auto;
      border-radius: 4px;
      margin-bottom: 7px;
      margin-top: 4px;

      &:hover {
        background: $blue !important;
        color: $white !important;
      }
    }

    .dropdown-toggle::after {
      display: none !important;
    }
  }

  .dropdown-menu {
    border: none;
    @include box-shadow (0, 0, 15px, 1px, rgba(81, 77, 92, .2));
  }
}

.nar-robo {
  align-items: center;
}

@media only screen and (max-width: 991px) {

  #navbar .nav-item {
    &:first-child {
      margin-top: 20px;
    }

    margin-left: 0px;
    text-align: left;

    a {
      font-size: 1.4rem;
      padding-left: 0;
      color: #ffffff !important
    }
  }
}

@media only screen and (max-width: 767px) {

  .navbar-investor {
    .navbar-brand {
      width: 100px;
    }
  }

}

@media only screen and (max-width: 576px) {

  .navbar-investor .navbar-toggler-right {
    top: 7px;
  }

  .navbar-investor {
    .navbar-brand {
      margin-left: 35px;
    }
  }
}

@media only screen and (max-width: 360px) {
  #header {
    .navbar-brand {
      width: 80px;
    }
  }

}
