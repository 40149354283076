@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #navbar .nav-item {
    margin-left: 0px;
  }

}

@media only screen and (min-width: 992px) {
  .perfect-acc {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {

  .fa {
    font: normal normal normal 14px/1 FontAwesome !important;
  }

  .table thead {
    th {
      white-space: nowrap;
    }
  }

  /* Add horizontal scroll portfolio detail navbar */
  .tab-scrollX {
    position: relative;

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent !important;
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
      border: 2px solid transparent !important;
    }
  }

  .tab-scrollX .nav-tabs {
    width: auto;
    white-space: nowrap; // overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    border: 0;
    padding-bottom: 3px;
  }

  .tab-scrollX .nav-tabs>li,
  .tab-scrollX .tabs>li {
    float: none;
    display: inline-block;
    width: auto;
    line-height: 22px !important;
    margin-right: 0px !important;
    margin-left: -1px !important; // border-bottom: 3px solid #D8D8D8;

    a {
      padding-left: 5px;
      padding-right: 5px;
      vertical-align: middle;
      display: inline-block;
      line-height: normal;
    }

    &.active {
      background: transparent;
    }

    &:first-child {
      padding-left: 0;
    }

    +li {
      margin-left: 3px !important;
      margin-right: 3px !important;
    }
  }

  .tab-scrollX .tabs>li {
    a {
      color: $dark-gray;
    }

    &.active {
      border-bottom: 3px solid $blue;

      a {
        color: $blue;
      }
    }
  }

  fieldset {
    .re-captcha {
      margin-bottom: 0 !important;
    }

    .btn__fpass {
      padding-top: 0px;
    }
  }
}

/// Fix Captcha on IPhone 6 7 Plus /
@media only screen and (min-width: 414px) and (max-width: 420px) {

  .recaptcha,
  #recaptcha {
    min-height: 91px;
  }
}



@media only screen and (max-width: 480px) {

  //Common
  .m-top0 {
    margin-top: 15px !important;
  }

  //Modal
  .modal-dialog {
    // width: 95% !important;
    margin-top: 45px;
    margin-left: 7px;
    margin-right: 7px;
  }

  .send-done {
    min-width: inherit;
    width: 300px;

    .send-content-success {
      padding: 0;
      white-space: normal;
    }
  }

  .table-style {
    tbody {
      .btn {
        margin: 0 auto;
      }
    }
  }

  .mb-pd-left {
    padding-left: 7.5px;
  }

  .mb-pd-right {
    padding-right: 7.5px;
  }

  .table-style,
  .table-style-2 {
    .table {
      td {
        white-space: nowrap;
      }

      // td,
      // th {
      //   padding: 0.75rem .5rem;
      // }
    }
  }

  asset-management {
    .table-style {
      .table {

        td,
        th {
          padding: 0.75rem;
        }
      }
    }

    .btn-transfer {
      padding: .5rem;
    }
  }

  .text-area {
    &.form-control {
      padding-bottom: 4.2rem;
    }
  }

  .hidden-down {
    display: none !important;
  }

  .fix-height {
    height: auto;
  }

  .hidden-cols {
    display: none !important;
  }

  .table {

    th.mobile-col,
    td.mobile-col {
      padding: 0.5rem 0;
    }

    th.wrap-text,
    td.wrap-text {
      white-space: normal;
    }
  }

 

  .table-style-sum {
    .total-sum {
      width: 80px;
    }
  }

  .mobile-table {
    table-layout: fixed;

    th {
      padding: 15px;
    }

    td {
      padding: 0.75rem 0.5rem !important;

      .btn-delete {
        padding-right: 10px;
      }

      .btn--edit {
        img {
          width: 12px;
        }
      }
    }

    .sub-tr {
      th {
        width: 33.33%;
      }
    }
  }

  .td-align-number {
    text-align: right !important;
  }

  .foot-tp {
    display: none;
  }

  .navbar-investor {
    .navbar-right {
      top: 5px;
    }
  }

  // #header {
  //   padding-top: 10px;
  // }

  .table-style,
  .table-style-2 {
    .mobile-col {
      .btn-exportIssuer {
        font-size: 0.9rem !important;
      }
    }
  }


  .mobile-em {
    display: inline-block !important;
  }

  trading-order {

    .table-style .btn--edit,
    .table-style-2 .btn--edit {
      right: -10px;
    }
  }
  .pencil {
    .btn--edits {
      left: -7px !important;
    }
  }

  .login-input {
    font-size: 1.2rem !important;
    font-family: myfont_bold !important;
    height: 50px !important;
  }

  .pre-line {
    strong {
      span {
        font-size: 4rem;
      }
    }
  }


  .asset-mobile-ws {
    white-space: normal !important;
  }
w

  .btn--fix {
    width: 100%;
    height: 36px;
    font-size: 17px;
  }

  // phong code 4/6/2021
  .dropdown-display-value {
    min-width: 0 !important;
  }

  .btn-transfer {
    margin-left: 10px;
    font-size: 14px;
  }

  .loginPage .card-title {
    color: black;
  }

  .img-mio {
    height: 300px;
    width: 350px
  }

  .invest-now {
    margin-bottom: 50px;
  }

  .groups-btn {
    .btn {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {

  // phong code 4/6/2021
  .dropdown-display-value {
    min-width: 120px !important;
  }

  .popover {
    font-family: OpenSans;
  }

  input.file-upload {
    height: 100% !important;
  }
}

@media (max-width: 576px) {
  .container {
    width: inherit;
  }
}

@media only screen and (max-width: 1024px) {
  .nds .btn-tooltip {
    display: none !important;
  }

  .hidden-landscape {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hidden-column {
    display: none;
  }

  .login {
    font-size: 17px !important;
    font-family: myfont_bold !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {


  .table {
    td {
      white-space: nowrap;

      .btn {
        font-size: 0.9rem !important;
      }
    }
  }

  .tb-padding {
    &--left {
      padding-left: 10px;
    }

    &--right {
      padding-right: 10px;
    }
  }

  .tb-margin {
    &--top {
      margin-top: 10px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .align-middle {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .dt-title {
    margin-top: -7px;
  }

  .copys {
    display: flex;
    align-items: center;
  }


  .table__title--filter {
    padding-left: 210px;
  }
}

@media only screen and (max-width: 768px) {
  .hidden-ipad {
    display: none;
  }

  #focusEmail {
    font-size: 15px;
  }

  .fixed-bond {
    padding-top: 10px;
  }

  .note-payment {
    font-size: 1rem;
  }

  .dropdown-content {
    span {
      font-size: 1.2rem !important;
    }
  }

  .canvas-container {
    height: auto !important;
    overflow: hidden;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 569px) {
  .modal--style2 {
    min-width: 450px !important;
    max-width: 90%;
  }

  .horizon-mobile {
    max-height: calc(100vh - 180px);
    overflow-y: auto;

    +.modal-footer {
      box-shadow: 0px -3px 4px -2px rgba(0, 0, 0, 0.37);
      z-index: 3;
    }
  }

  .tb-padding--left {
    padding-left: 7.5px;
  }

  .tb-padding--right {
    padding-right: 7.5px;
  }

  .align-middle {
    align-items: center;
    display: flex;
    margin: 0 auto;
    margin-top: 1.3rem;
  }

  #change-pass {
    max-width: 360px !important;
  }


  .loginPage {
    &__inner {
      max-width: 350px;
      padding: 10px;
    }

    &__logo {
      min-height: 55px;

      img {
        max-width: 150px;
      }
    }
  }

  .table__title--filter {
    padding-left: 210px;
  }

}

@media only screen and (max-width: 812px) and (min-width: 569px) {

  .hidden-landscape {
    display: none;
  }


  .accordian-body {

    input,
    textarea {
      font-size: 0.8rem;
    }
  }

  .btn--mua {
    font-size: 13px !important;
  }

  .table-style,
  .table-style-2 {
    .mobile-col {
      .btn-exportIssuer {
        font-size: 13px !important;
      }
    }
  }

  .info-payment-popup {
    .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0 !important;
      padding: 0;
      max-width: 100%;

      .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
        margin-top: 0;
      }
    }
  }
}

/* Fix Iphone 5 */

@media only screen and (max-width: 320px) {
  #account-info label,
  #change-pass label,
  #additional-info label,
  #security label {
    font-size: 12px !important;
  }
  .loginPage__register,
  .loginPage__inner {
    max-width: 320px;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

  .loginPage__inner .card-block {
    padding: 1rem 0.5rem 0;
  }

  .tb-padding--left {
    padding-left: 15px !important;
  }

  .tb-padding--right {
    padding-right: 15px !important;
  }
}


@media only screen and (min-width: 320px) and (max-width: 660px) {

  .dropdown .dropdown-display-value::after {
    content: "";
    width: 10px;
    height: 10px;
    top: 10px;
    right: 17px;
  }


  .modal-dialog {

    .modal-body {
      max-height: 400px;
      overflow-y: auto;
      margin-bottom: 0;
    }
  }

  .table-responsive {
    table {
      max-width: none !important;
    }
  }
}

@media (max-width: 375px) {
  #product-table .table.product-table_header .border-th .dropdown .dropdown-display-value::after {
    right: -7px !important;
  }
}





@media only screen and (max-width: 1024px) {

  .tab-content {
    .right-content {
      .guildline {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 990px) {
  .header-robo {
    background-position: 50%;
    height: 315px !important;

    .header-title {
      span {
        font-size: 36px !important;
      }
    }
  }
}

@media only screen and (max-height: 450px) {
  .modal-dialog>.modal-content {
    margin-top: 80px;
  }
}

@media (min-width: 576px) {

  .recurring-investment-modal,
  .buy-product-modal,
  .form-update-account-egold-modal {
    .modal-dialog {
      max-width: 502px;
      min-width: 500px;
      margin: 30px auto;
    }
  }

}


@media screen and (max-width: 1997px) {
  .tab-scrollX {
    position: relative;

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent !important;
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
      border: 2px solid transparent !important;
    }

  }

  .tab-scrollX .nav-tabs {
    width: auto;
    white-space: nowrap; // overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    border: 0;
    padding-bottom: 3px;
  }

  .tab-scrollX .nav-tabs>li,
  .tab-scrollX .tabs>li {
    float: none;
    display: inline-block;
    width: auto;
    line-height: 22px !important;
    margin-right: 0px !important;
    margin-left: -1px !important; // border-bottom: 3px solid #D8D8D8;

    a {
      padding-left: 5px;
      padding-right: 5px;
      vertical-align: middle;
      display: inline-block;
      line-height: normal;
    }

    &.active {
      background: transparent;
    }

    &:first-child {
      padding-left: 0;
    }

    +li {
      margin-left: 3px !important;
      margin-right: 3px !important;
    }
  }

  .tab-scrollX .tabs>li {
    a {
      color: $dark-gray;
    }

    &.active {
      border-bottom: 3px solid $blue;

      a {
        color: $blue;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (max-width: 567px) {
  html {
    font-size: 14px;
  }
}


@media (min-width: 1600px) {
  .container {
    width: 1540px;
    max-width: 100%;
  }

  html {
    font-size: 17px;
  }
}

@media (min-width: 1024px) {
  .e-sign-modal {
    .modal-dialog {
      min-width: 650px;
    }
  }
}

@media (min-width: 568px) and (max-width: 812px) {
  .container {
    width: 100%;
  }

  #header.active {
    overflow-y: scroll;
    overflow-x: hidden;
  }

}

@media (max-width: 1199px) and (min-width: 992px) {
  .container {
    max-width: 1110px;
    min-width: 960px;
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  #trading-management {
    #product-table {
      .table {
  
        td {
          padding: .75rem .5rem;
        }
      }
    }
    #product-table .table thead th,
    #product-table .table tr td {
      padding: 0.5em 0.35em;
    }
  }
}


@media only screen and (max-width: 480px) {

  .table-style {

    table {
      tr {
        th {
          border-bottom: 1px solid $table-border;
        }
      }
    }

    .table__title {
      border-radius: 0;
    }

    .btn--mua {
      height: 100%;
      width: 44px;
      padding: 5px;
    }

    .mobile-column {
      display: table-cell;
    }

    .desktop-column {
      display: none;
    }
  }
}

@media only screen and (min-width: 481px) {
  .table-style {
    table {
      tr {
        th {
          border-bottom: 1px solid $table-border;
        }
      }
    }

    .mobile-column {
      display: none;
    }

    .desktop-column {
      display: table-cell;
    }
  }
}

@media (max-width: 991px) {
  .container {
    width: 911px;
    max-width: 100%;
  }
}


@media (min-width: 1200px) {
  .container {
    width: 1300px;
  }
}

@media (max-width: 420px) {
  .note-table {
    display: block;
    // margin-bottom: 0;
  }
}

@media (min-width: 421px) {
  .note-table {
    display: none;
  }
}

@media (min-width: 568px) and (max-width: 814px) {
  .payment-info-modal {
    display: none !important;
    overflow: scroll !important;
  }

  .e-sign-modal {
    .modal-dialog {
      min-width: 575px;
      max-width: none;
    }

    .modal-content {
      .modal-header {
        padding: 0;
      }

      .modal-body {
        padding: 0 15px;
      }
    }
  }

  #sig-canvas {
    margin-bottom: 0;
  }
}


@media only screen and (max-width: 420px) {
  .offcanvas {
    width: 100%;
    min-width: 210px !important;
  }
}

