.note-payment {
  // font-family: myfont_bold !important;
  white-space: break-spaces;
  font-size: 14px;

  @media (max-width: 480px) {
    font-family: OpenSans6 !important;
    // font-size: 0.75rem !important;
  }
}

.success {
  background-color: #34bfa3;
  color: #fff;
  padding: 2px 10px;
  border-radius: 0.75rem;
  white-space: nowrap;
  font-size: 0.75rem;
}

.success-status {
  color: #34bfa3;
  white-space: nowrap;
  font-size: 0.8rem;

  @media (max-width: 767px) {
    font-family: OpenSans6 !important;
    font-size: 0.75rem;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
}

.in_cart {
  color: #ff991f;
  white-space: nowrap;
  font-size: 0.8rem;

  @media (max-width: 767px) {
    font-family: OpenSans6 !important;
    font-size: 0.75rem;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
}

.pending {
  background-color: #f4516c;
  color: #fff;
  padding: 2px 10px;
  border-radius: 0.75rem;
  font-size: 0.75rem;
  white-space: nowrap;
  font-size: 0.75rem;
}

.cancel {
  color: $red;
  white-space: nowrap;
  font-size: 0.8rem;
  font-family: myfont_bold !important;

  @media (max-width: 767px) {
    font-family: OpenSans6 !important;
    font-size: 0.75rem;
  }
}

.complete {
  color: $hover-menu;
  white-space: nowrap;
  font-size: 0.8rem;

  @media (max-width: 767px) {
    font-family: OpenSans6 !important;
    font-size: 0.75rem;
  }
}

.in-process {
  color: $green;
  white-space: nowrap;
  font-size: 0.8rem;

  @media (max-width: 767px) {
    font-family: OpenSans6 !important;
    font-size: 0.75rem;
  }
}

.maturity {
  color: $dark-blue;
}


.input-copys {
  position: relative;

  .btn-copy {
    border: 1px solid #06a53b !important;
    width: auto;
    position: absolute;
    right: 7px;
    bottom: 7px;
    padding: 0.25rem;
    font-size: 14px;

    &:focus {
      color: #06a53b;
    }
  }

  .btn-copy2 {
    border: 1px solid #06a53b !important;
    width: auto;
    position: absolute;
    right: 6px;
    bottom: 10px;
    padding: 0.35rem;
  }
}

.divider {
  display: block;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: -8px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  width: 100%;

  span {
    display: inline-block;
    position: relative;
    color: $black;
    cursor: default;
    font-size: 13px;
  }
}

.divider>span:before {
  margin-right: 15px;
  right: 100%;
}

.divider>span:after {
  left: 100%;
  margin-left: 15px;
}

.divider>span:before,
.divider>span:after {
  background: #ced0d4;
  content: "";
  height: 1px;
  position: absolute;
  top: 50%;
  width: 9999px;
}

.table-style-sum {
  margin-bottom: 0;
  border-top: 1px solid $table-border;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  margin-top: 0;
  font-family: myfont_bold;

  tr {
    td {
      color: $txt-blue;
    }
  }

  @media only screen and (max-width: 767px) {
    font-family: OpenSans6;
  }

  .table {
    margin-bottom: 0;

    a.btn-money {
      color: $white;
    }
  }

  .total-sum {
    text-align: center !important;
    background: $dark-blue;
    color: $white;
    border-top: 0;
    width: 140px;
    display: inline-block;
  }

  &.table-sum--style1 {
    td {
      font-size: calc(20 / $root) * 1rem;
    }
  }
}

.panel-title {
  padding-top: 15px;
}

.send-done {
  // background-color: $dark-blue;
  padding: 15px;
  border-radius: 5px; // margin: 0 15px;
  // box-shadow: 0 2px 4px #9e9e7f;
  min-width: 390px;
  text-align: center;

  .send-content-success {
    padding: 10px 0;
    white-space: pre-line;
    // color: #fff;
  }
}

.send-done {
  .panel-body {
    padding-bottom: 15px;

    span {
      // color: $white;
      font-size: 1.3rem;
    }
  }

  .authen-title {
    .panel-title {
      color: #fff;
    }
  }
}

.send-img {
  padding-top: 15px;

  img {
    width: 35%;
  }
}


.export-file {

  label {
    font-size: 1.6rem;
    font-family: 'myfont-bold';
    color: $blue;
  }

  .dropdown-menu {
    button {
      background-color: transparent;
    }

    @media (max-width: 480px) {
      min-width: 117px;
      left: -96px;
    }
  }
}

.product-notice {
  list-style-type: none;
  padding-left: 0;
  background: #F2F2F2;
  padding: 10px;
  margin: 1rem 0;
  border-radius: 5px;
  border: 1px solid #E3E9F6;
  color: $black;

  label {
    margin-bottom: 0;
    color: $black;
    font-family: OpenSans6;
    font-style: italic;
  }

  span {
    margin-left: 10px;
    font-style: italic;
    display: block;
    text-align: justify;
  }
}


.fix-font {
  label {
    strong {
      font-size: 1rem;
    }
  }

  .input-copys .btn-copy {
    bottom: 4px;
  }

  .input-copys .btn-copy2 {
    bottom: 7px;
  }
}

#transaction-history {

  .export-btn {
    @media (max-width: 480px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .export-file {
    // margin-bottom: 15px;

    .form-group,
    .btn-export {
      margin-bottom: 0;
    }

    .input-group {
      position: relative;

      &-btn {
        position: absolute;
        right: 0;
      }
    }
  }
}

#dk-gd,
#transaction-history,
#transaction-history-gold {
  .table-responsive {
    table {
      tbody {
        tr:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

#transaction-history {
  .table__title {
    padding-bottom: 0;
  }
}

.status__event {
  &:hover {
    cursor: pointer;
  }
}


.block-nodata {
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    font-family: OpenSans7;
    font-size: 16px;
    line-height: 19px;
    color: #323648;
    margin-top: 15px;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    color: #B9B9B9;
  }
}


.change-amount {
  &:hover {
    path {
      stroke: #0368FF;
    }
  }
}


.logo-issuer {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-right: 10px;
}


.gold-img {
  border: 1px solid #E3E9F6;
  margin-right: 10px;
  border-radius: 5px;

  width: 36px;
  height: 36px;
}




#label-ipo {
  background-color: $dark-orange;
  color: white !important;
  font-size: 12px;
  padding: 0 5px;
  border-radius: 5px;
  margin-bottom: 0;
  position: relative;

  display: flex;
  align-items: center;

  height: 24px;

  &::before {
    content: "";
    position: absolute;
    left: -7px;
    top: 9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent $dark-orange transparent;
    z-index: 9;
    transform: rotate(270deg);
  }
}

#label-new-product {
  background-color: #11ab62;
  color: white !important;
  font-size: 12px;
  padding: 0 5px;
  border-radius: 5px;
  margin-bottom: 0;
  position: relative;

  height: 24px;

  display: flex;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    left: -7px;
    top: 9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #11ab62 transparent;
    z-index: 9;
    transform: rotate(270deg);
  }
}

.asset-tabs,
.info-tabs {
  background: $white;
}

#fund {

  .error-text,
  .help-block {
    text-align: right;
  }
}

.m-top0 {
  margin-top: 0 !important;
}


.vertical-offset-100 {
  min-height: -webkit-calc(100vh - 40px);
  min-height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.horizontal-success,
.horizontal-fail {
  margin: 0 auto;
}

.one {
  .dropdown-display-value {
    min-height: 42px;
    height: auto !important;
  }
}
