
// Hard code zalo widget
.zalo-chat-widget {
    bottom: 7px !important;
    right: 7px !important;
    width: 45px !important;
    height: 37px !important;
  
    iframe {
      bottom: -15px !important;
      right: -11px !important;
      width: 60px !important;
      height: 60px !important;
    }
  
    #drag-left,
    #drag-right,
    #drag-top,
    #drag-bottom,
    #drag-top-left,
    #drag-top-right,
    #drag-bottom-left,
    #drag-bottom-right {
      display: none !important;
    }
}

//Egold
#iframe-container {
    iframe {
      width: 100% !important;
    }
}
//End Egold

.re-captcha {
    .error-text {
      padding-top: 5px;
    }
}

.ov {
    margin-bottom: 30px;
  
    h2 {
      font-family: myfont_bold;
      color: $dark-blue;
    }
}

chart-ant-robo {
  app-chart-js-pie-chart {
    canvas {
      height: 150px;
      width: 200px;
    }
  }
  .equal-table {
    .table td, .table th {
      border-top: none;
    }
  }
}


capture-image {
  max-width: 386px;
  height: 284px;
}

.otp-popup {
  input-number {
    input {
      text-align: center;
    }
  }
}


.filter_start_end_date {
  input {
    background-color: transparent;
    padding: 0;
    height: 28px;
    border: none !important;
    &:hover {
      cursor: pointer;
    }
  }
}
#trueid {
  p {
    margin-bottom: 0;
  }
}
input-number {
  width: 100%;
}