.modal-backdrop {
  z-index: 9999 !important;
}


.modal-content {
  border: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.33);
}

.modal-sm {
  max-width: 400px !important;
}

.modal-header {
  background-color: $dark-blue;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: center;
  display: block;
  padding: 15px 30px;
  border: none;

  @media (max-width: 480px) {
    padding: 15px;
  }

  .modal-title {
    color: $white;
    font-size: 1rem;
    width: 100%;
    // text-align: center;
    padding: 5px;
  }

  label {
    color: $white;
    font-size: calc(16.2/$root)*1rem;
    margin-bottom: 0;

    +label {
      display: block;
    }

    // padding: 0 10px;
  }

  .close {
    // position: absolute;
    position: fixed;
    top: -13px;
    right: -13px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 23px;
    opacity: 1;
    z-index: 10;
    background: $white;
    outline: none !important;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;

  .close {
    position: relative;
    top: 0;
    right: 0;
    background-color: transparent;
  }

  label {
    font-size: 26px;
  }
}

.modal-body {
  padding: 15px 30px;

  @media (max-width: 480px) {
    padding: 15px;
  }

  label {
    margin-bottom: 0;
    font-size: calc(16/$root)*1rem;

    &~em {
      display: block;
      color: #b0b0b0;
      font-size: calc(14/$root)*1rem;
    }
  }

  .help-block {
    color: #ff0103;
    font-size: calc(14/$root)*1rem;
    display: block;
  }

  input,
  .input-group-addon,
  .input-group-button {
    border-color: #E3E9F6;
  }

  .tt-buy-hgt {
    min-height: 32px;
    margin: 5px 0;
    display: flex;
    align-items: center;
  }

  .tt-buy-hgt {
    &.text-right {
      justify-content: flex-end;
    }
  }

  .label-name {
    color: $txt-blue;
    text-align: right;
    font-family: myfont_bold;

    @media (max-width: 767px) {
      font-family: tablefont_bold !important;
    }
  }

  .modal-heading {
    background: #F5F9FF !important;
    color: $black;
    border: 1px solid #E3E9F6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .popup-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #30363D;
    margin-top: 33px;
    margin-bottom: 10px;
  }

  .popup-message {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #30363D;
    margin-top: 20px;
    white-space: pre-line;
  }
}

.modal-footer {
  padding: 15px 30px;
  border-top: none;
  overflow: hidden;
  display: block;
  text-align: center;

  @media (max-width: 480px) {
    padding: 15px;
  }

  .text-center {
    margin: 0 auto;
  }


  button {
    background: #0368FF;
    border-radius: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    width: 267px;
    height: 36px;
  }

  .btn--green {
    padding: 0.5rem 1.7rem;
  }
}

.modal {
  text-align: center;
  padding: 0 !important;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all ease 1s;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; // margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  animation: appear 350ms ease-in 1;
}

.modal-content {
  .modal-body {
    overflow-y: auto;
    max-height: 700px;
  }
}

.md--border__right {
  border-right: 1px solid $white;
}

.confirm-title {
  font-size: 1.2rem;
  padding: 0 15px;
  text-align: center;
}
