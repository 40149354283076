#account-info,
#change-pass,
#additional-info,
#security,
#second-acc {
  // border: 2px solid #034581;
  // max-width: 820px;
  margin: 0 auto;
  padding: 20px 0;
  border-radius: 5px;

  h4 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  // label {
  //   font-size: 14px;
  //   margin-bottom: 0.5rem;
  // }
}

#second-acc,
#additional-info {
  background: #ffffff;
  border: 2px solid #E3E9F6;
  max-width: 820px;
}

#account-info {
  @media (min-width: 768px) {
    .btn-block {
      margin-top: 2px;
      height: 48px;
    }
  }

  .dropdown-toggle.disabled {
    &:after {
      display: none;
    }
  }

  .btn--green:disabled,
  .btn--reset:disabled,
  .btn--green.disabled,
  .btn--reset.disabled {
    opacity: .2 !important;
  }
}

#change-pass {
  max-width: 540px;

  .form-group {
    margin-bottom: 1.3rem;
  }

  .btn {
    width: 100%;
  }

  .info-form {
    padding: 0 30px;
  }
}

#additional-info,
#security {
  padding: 0;

  .error-text {
    text-align: right;
  }

  .card {
    border: 0;

    &-header {
      background: transparent;
      border: 0;

      .btn-link {
        color: $blue;
        font-size: 1.2rem;
        padding: 0;
        position: relative;
        padding-left: 25px;
        white-space: normal;
        text-align: left;

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &:after {
          display: inline-block;
          width: 0;
          height: 0;
          vertical-align: middle;
          content: "";
          border-left: .3em solid $blue;
          border-top: .3em solid transparent;
          border-bottom: .3em solid transparent;
          position: absolute;
          left: 0px;
          top: 0px;
          font-size: 1.8rem;
        }
      }

      &.active {
        .btn-link {
          &:after {
            border-top: .3em solid $blue;
            border-right: .3em solid transparent;
            border-left: .3em solid transparent;
            margin-top: 5px;
            left: -5px;
          }
        }
      }
    }

    &-body {
      padding: .25rem 45px;

      a {
        display: block;
        color: $blue;
        padding: 5px 0;

        &:hover {
          color: $dark-blue;
        }
      }
    }
  }

  #accordion {
    border-bottom: 1px solid $dark-blue;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  .info-row {
    padding: 10px 30px;
  }

  .small {
    color: $black;
    font-size: 0.8rem;
    font-style: italic;
    font-family: myfont;
  }
}

.info-title {
  padding: 0px 20px;
}

.info-form {
  padding: 0 20px 20px;

  @media (min-width: 768px) {
    >.row>.col-md-6:nth-child(even) {
      padding-left: 10px;
    }

    >.row>.col-md-6:nth-child(odd) {
      padding-right: 10px;
    }
  }
}

.id {
  color: $blue;
  display: inline-block;
  font-size: 1.1rem;
}

.info-account {
  label {
    font-size: 14px;
  }

  .btn {
    display: block;
    width: 100%;
  }
}

.group-btn {
  padding: 0;
  margin-top: 25px;

  @media all and (max-width:480px) {
    margin-top: 15px;
  }
}


#fatca {
  border-bottom: 2px solid $dark-blue;
}


.date,
.month {
  width: 70px;
  margin-right: 15px;

  .form-control {
    text-align: center;
    padding: .5rem;
  }
}

.year {
  width: 90px;

  .form-control {
    text-align: center;
    padding: .5rem;
  }
}

.form-control {
  @include placeholder {
    color: $dusty-gray !important;
    font-size: calc(14/16)*1rem;
    font-style: italic;
  }
}


@media all and (max-width:767px) {
  .id {
    font-size: 1.2rem;
  }
}

@media all and (max-width:480px) {
  .info-form {
    padding: 0 15px 5px !important;
  }

  .info-title {
    padding: 5px 15px;
  }

  .btn-add-account {
    margin-left: 15px;
  }

  #additional-info {

    .info-title,
    .info-row {
      padding: 5px;
    }

    .btn-add-account {
      margin-left: 5px;
    }
  }
}
.status-check {
  display: flex;
  width: 100%;
  background-color: #FFE7D2;
  border: 1px solid #F2994A;
  border-radius: 3px;
  color: #F2994A;
  font-size: 14px;
  padding: 10px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  img {
    margin-right: 20px;
  }
}

.upper-case {
  text-transform: uppercase;

  @include placeholder {
    text-transform: none;
  }
}

.info-account {
  .input-group-addon {
    background-color: transparent;
  }
}

uploading-icon {
  position: absolute;
  top: 30%;
  right: 0;
  left: 16%;
  bottom: 0;
  width: 250px;
  height: 50px;

  div {
    width: 250px;
    height: 50px;
  }
}

.icon-view-password {
  cursor: pointer;
  position: absolute;
  bottom: 12px;
  right: 16px;

  img {
    width: 24px;
    height: 24px;
  }
}