@import "./font";
@import "./button";
@import "./animation";
@import "./bootstrap_component";
@import "./investor";
@import "./component_global";

.modal-backdrop {
  display: none !important;
}

body {
  font-family: OpenSans4;
  background-color: #ffffff;
  color: #000;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  margin: 0;
  overflow-x: hidden;

  @include placeholder {
    color: $dusty-gray !important;
    font-style: italic;
    font-size: 12px;
  }

}

a:not([href]):not([tabindex]) {
  color: $base-blue;
}

.form-control:disabled,
.form-control[readonly] {
  pointer-events: none;
}

.modal-open {
  overflow: hidden !important;
}

html {
  font-size: 16px;
}


a {
  cursor: pointer;
  @include transition(all ease 0.3s);

  &:hover {
    outline: none;
    text-decoration: none !important;
  }

  color: $base-blue;

  &.udl {
    text-decoration: underline;
  }
}


strong {
  font-family: OpenSans6 !important;
  // color: #1F1F1F;
}

.weight-bold {
  font-family: OpenSans6 !important;
}

.m-b-15 {
  margin-bottom: 15px;
}

.isCenter {
  position: fixed;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.required {
  color: #cf201d;
  font-weight: normal;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0 !important;
}

form {
  .row {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  .form-group {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eceeef !important;

}

textarea.disabled {
  background-color: #eceeef !important;
}

.label {
  color: $black;
}

textarea {
  font-size: 14px;
  padding: 0.5rem 0.75rem;

  &.disabled {
    background-color: #eceeef;
    opacity: 1;
    color: #464a4c;
  }
}

.flexbox {
  display: flex;
}

.flexbox-aligns-center {
  display: flex;
  align-items: center;
}

.flexbox-all {
  display: flex;
  align-items: center;
  justify-content: center;
}


.flex-end {
  display: flex;
  justify-content: flex-end;
}

.input-group-addon {
  background: #fff;
}

.form-control {
  font-size: calc(14/16) * 1rem;
  line-height: 1.3rem;
  height: $inp_height;
  border-radius: 0.35rem;
  font-family: myfont;

  &:focus,
  &:visited {
    border-color: $base-blue !important;

  }

  &:disabled {
    background-color: #eceeef;
    -webkit-text-fill-color: #464a4c;

    +.input-group-addon {
      background-color: #eceeef;
    }
  }

  @media (max-width: 767px) {
    font-size: calc(15/16) * 1rem !important;
  }
}

//Fix padding
.fix-padding-left {
  padding-left: 7.5px !important;
}

.fix-padding-right {
  padding-right: 7.5px !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.stt-green {
  color: $hover-menu;
}


.fail {
  background-color: #dc143c;
}

.done {
  background-color: #06a53b;
}

.uploading {
  background-color: #034581;
}

.up,
.success-text {
  color: #06A53B !important;
}

.down {
  color: red !important;
}

.blue,
.up-blue,
.f-blue {
  color: $blue;
}

.same0,
.f-orange {
  color: #ef921e;
}

.pledge {
  color: $orange;
}

.error-text,
.error,

.help-block {
  color: red !important;
  font-size: 12px;
  display: block;
  text-align: left;
}

.dark-blue {
  color: $dark-blue;
}

.hidden {
  display: none;
}

.custom-disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: #eceeef !important;
  -webkit-text-fill-color: #464a4c !important;
}

.logo {
  padding: 15px 15px 35px;
  text-align: center;

  img {
    max-width: 200px;
  }
}

.right {
  float: right;
}

.left {
  float: left;
}

.close img {
  width: 60%;
}

.accepted {
  color: $black;
}

.sum {
  color: $white;
}

.text-area {
  &.form-control {
    height: auto;
  }
}

textarea {
  border: 1px solid #ababab;
  border-radius: 5px;
  resize: none;
  width: 100%;
}

a.active-link {
  cursor: pointer !important;
  color: $dark-blue !important;

  &:focus,
  &:hover {
    color: $blue !important;
  }
}

.text-wrap {
  word-break: break-all;
}

// Align css
.center {
  text-align: center;
}

/* Define Scroll Broser */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f2f2f2;
  background-color: #dbdbdb;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #BDBDBD;
  border: 2px solid #BDBDBD;
  border-radius: 5px;
}

.tab-pane.active {
  animation: fadeIn 0.5s ease-out;
}

.text-ellips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fix-height {
  height: 48px;
}


//Fix text-area ouline
textarea {
  &.disabled:focus {
    border-color: $blue !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 175, 239, 0.603);
    outline: none;
  }
}

.no-break {
  white-space: nowrap !important;
}

.input-group {
  .searchs {
    border: 2px solid $blue !important;
    height: 48px;
  }
}

.align-number {
  text-align: right !important;
}

.align-text {
  text-align: center;
}

.input-group-addon {
  color: #464a4c !important;
}


.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.85rem 1.15rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #eceeef;
  border: 1px solid #ababab;
  border-radius: 0.25rem;

}

.flex-direct {
  flex-direction: column;
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.form-group {
    margin-bottom: .5rem;
  }
}

.wrapper {
  margin-top: 55px;
}

.w-25 {
  width: 25%;
}

.w-33 {
  width: 33.3%;
}

.w-40 {
  width: 40%;
}


//-------.progress-bar -----------------
.progress-bar {
  background-color: $white;
}

.progress {
  background-color: rgba(255, 255, 255, 0.5);
}

.no-border {
  border: none !important;
}


.custom-link {
  color: #0368FF !important;
  cursor: pointer;
}

.input-group {
  input {
    border-color: #E3E9F6 !important;
    border-radius: 5px !important;
  }

  span.unit {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;

    padding: 0 15px;
    border-left: 1px solid #E3E9F6;
  }
}


.notFound {
  margin-top: 50px;
}

.line-cut {
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
  width: 95px;
  text-align: center;
  background-color: white;
}

.dropdown-menu-right {
  right: unset;
}

#transaction-history {
  .transaction-history {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
