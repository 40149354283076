// Popover
.popover__traituc {
  cursor: pointer;
}

.popover.bs-tether-element-attached-bottom,
.popover.popover-top {
  margin-top: -23px;
  left: 95px !important;
}

.popover {
  z-index: 1029;
  padding: 0;
  border: none;
  box-shadow: 0 0 15px 1px rgba(81, 77, 92, 0.2);
  position: absolute; // width: 250px;
  margin-left: -25px;

  span {
    color: $white;
  }

  &.popover-top::after,
  &.popover-top::before {
    // margin-left: -65px;
    position: absolute;
    left: 15%;
  }

  &.popover-bottom::after,
  &.popover-bottom::before {
    left: 58%;
  }

  &__user {
    padding: 9px 15px !important;
    background-color: $blue;
  }

  &__profile {
    padding: 9px 15px !important;
    background-color: $blue;
    color: $white;
  }

  &__bonds {
    padding: 9px 10px !important;
  }

  &__table {
    thead {
      color: $white;
      background-color: $blue;
    }

    tbody {
      background-color: $white;

      tr:nth-child(odd) {
        background: #eaeaea;
      }
    }

    th {
      padding: 10px;
      font-size: 0.7rem;
      font-weight: normal;
    }

    td {
      padding: 2px 10px;
    }

    &-fix {
      max-height: 190px;
      overflow-y: scroll;
    }
  }
}

.popover-content {
  padding: 0;

  .popover-body {
    padding-bottom: 0;
    border: none;
  }
}

.popover.popover-top::after,
.popover.bs-tether-element-attached-top::after {
  border-top-color: $blue !important;
}

.popover.popover-bottom::after,
.popover.bs-tether-element-attached-bottom::after {
  border-bottom-color: $blue !important;
}

.popover.bs-tether-element-attached-right::after,
.popover.popover-left::after {
  border-left-color: $blue;
}

@media only screen and (max-width: 480px) {

  //Popover
  .popover {
    &.popover-top::after,
    &.popover-top::before {
      left: 60%;
    }

    &.left::after,
    &.left::before {
      left: 15%;
    }
  }
}

.depositguide-form .dropdown-toggle,
.issuers .dropdown-toggle,
.banks .dropdown-toggle {
  color: #707070;
  background: transparent; // border: 1px solid #707070;
  text-transform: none;
}


//Custom checkmark
.labelRadio {
  display: inline-block;
  position: relative;
  padding-left: 31px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 16px;
  white-space: nowrap;
  line-height: 2;
  font-family: myfont !important;
}

.labelRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.labelRadio:hover input~.checkmark {
  background-color: #ccc;
}

.labelRadio input:checked~.checkmark {
  background-color: $white;
  border-color: $blue;
}

.labelRadio input:checked~.checkmark:after {
  display: block;
}

.labelRadio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $base-blue;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid #727272;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


//checkbox animation
.checkbox-animated {
  position: relative;
  // margin-top: 10px;
  // margin-bottom: 10px;

  input[type="checkbox"] {
    display: none;

    &:disabled~label .box {
      border-color: #777;
      background-color: #e6e6e6;
    }

    &:disabled~label .check {
      border-color: #777;
    }

    &:checked~label .box {
      // opacity: 0.5;
      -webkit-transform: scale(0) rotate(-180deg);
      -moz-transform: scale(0) rotate(-180deg);
      transform: scale(0) rotate(-180deg);
    }

    &:checked~label .check {
      opacity: 1;
      -webkit-transform: scale(1) rotate(45deg);
      -moz-transform: scale(1) rotate(45deg);
      transform: scale(1) rotate(45deg);
    }
  }

  label {
    cursor: pointer;
    padding-left: 28px;
    font-weight: normal;
    margin-bottom: 0;

    & span {
      display: block;
      position: absolute;
      left: 40%;
      top: 0;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      transition-duration: 0.3s;
    }

    & .box {
      border: 2px solid #919090;
      height: 20px;
      width: 20px;
      z-index: 888;
      -webkit-transition-delay: 0.2s;
      -moz-transition-delay: 0.2s;
      transition-delay: 0.2s;
    }

    & .check {
      top: -7px;
      left: 45%;
      width: 12px;
      height: 24px;
      border: 2px solid #f5222d;
      border-top: none;
      border-left: none;
      opacity: 0;
      z-index: 888;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);

      -webkit-transition-delay: 0.3s;
      -moz-transition-delay: 0.3s;
      transition-delay: 0.3s;
    }
  }
}

.checkbox-animated-inline {
  &.checkbox-animated-inline {
    display: inline-block;

    &+.checkbox-animated-inline {
      margin-left: 10px;
    }
  }
}

//Check box
.mt-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  @include transition(all 0.3s);
  font-weight: normal;
  color: #c0c0c0;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  span {
    border: 1px solid #a3a7aa;
    position: absolute;
    top: 1px;
    left: 0;
    height: 20px;
    width: 20px;
    background: 0 0;

    &:after {
      left: 7px;
      top: 1px;
      width: 5px;
      height: 12px;
      border: solid $blue;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      @include transform(rotate(45deg));
    }
  }
}

.mt-checkbox-list .mt-checkbox,
.mt-checkbox-list .mt-radio,
.mt-checkbox>input:checked~span:after,
.mt-radio-list .mt-checkbox,
.mt-radio-list .mt-radio,
.mt-radio>input:checked~span:after {
  display: block;
}

.mt-checkbox>span:after {
  content: "";
  position: absolute;
  display: none;
}
