/*--footer Homepage---*/

footer ul li {
  list-style-type: none;
  padding: 3px 0;
}

/* Footer Main */
#footer {
  // background: $base-blue;
  color: $black;
  // margin-top: 100px;
  position: absolute;
  // bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin-top: 50px;

  .copyright {
    // color: $white;
    padding: 10px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .language {
    .dropdown-toggle {
      background: transparent;
      font-size: 12px;
      color: $blue;
      margin-top: 8px;
      text-transform: none;
    }
  }
}
